<template>
  <div class="footer__useful-informations">
    <div class="footer__useful-informations__title">
      <span>
        {{ $t('Useful Informations') }}
      </span>
      <component
        class="footer__useful-informations__title__icon"
        :is="showMobileContent ? 'IconMinusBlack' : 'IconPlusBlack'"
        @click="showMobileContent = !showMobileContent"
      />
    </div>
    <ul
      v-if="usefulInformationsContent && usefulInformationsContent.length > 0"
      class="footer__useful-informations__list"
      v-show="showMobileContent || $device.isDesktop"
    >
      <template v-for="(element, index) in usefulInformationsContent">
        <li
          v-if="element.link && element.label"
          class="footer__useful-informations__list__element"
          :key="`usefulInformations-${index}`"
        >
          <SfLink :link="localePath(element.link)">
            {{ element.label }}
          </SfLink>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
import { SfLink } from '@storefront-ui/vue';
import { IconPlusBlack, IconMinusBlack } from '~/components/General/Icons';
import { useMarkets } from '~/composables';

export default defineComponent({
  name: 'UsefulInformations',
  components: {
    SfLink,
    IconPlusBlack,
    IconMinusBlack,
  },
  props: {},
  setup() {
    const { menu, search: searchMenu } = useMenu('footerUsefulInformations');
    const usefulInformationsContent = ref();
    const showMobileContent = ref(false);
    const { isUeMarket } = useMarkets();

    useFetch(async () => {
      try {
        await searchMenu({ code: 'usefulinfo' });
        usefulInformationsContent.value = isUeMarket.value
          ? menu.value.items
          : menu.value.items.map((m) =>
              m.link?.includes('recesso-gratuito')
                ? {
                    label: 'Resi',
                    link: '/resi',
                  }
                : m
            );
      } catch {
        console.warn('Could not get footerUsefulInformations menu content');
      }
    });

    return {
      usefulInformationsContent,
      showMobileContent,
    };
  },
});
</script>

<style lang="scss">
.footer {
  &__useful-informations {
    padding-top: var(--spacer-base);
    padding-left: var(--spacer-base);
    padding-right: var(--spacer-base);
    padding-bottom: 1.875rem;
    &__title {
      @include mobile-h6;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__list {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacer-sm);
      margin: 0;
      padding: var(--spacer-base) 0 0 0;
      &__element {
        @include paragraph-s;
        --link-text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@include from-desktop-min {
  .footer {
    &__useful-informations {
      padding-top: 3.375rem;
      padding-left: 3.375rem;
      padding-right: 3.375rem;
      &__list,
      &__title {
        margin: 0 auto;
      }
      &__title {
        @include desktop-h6;
        &__icon {
          display: none;
        }
      }
      &__list {
        display: grid;
        grid-auto-columns: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-template-rows: repeat(8, 1fr);
        grid-row-gap: 11px;
      }
    }
  }
}
</style>
