<template>
  <div class="socials">
    <a
      href="https://www.facebook.com/SDC.SogniDiCristallo/"
      target="_blank"
      class="socials-icon"
    >
      <SocialIconFacebookBlack />
    </a>
    <a
      href="https://www.instagram.com/sogni.di.cristallo/"
      target="_blank"
      class="socials-icon"
    >
      <SocialIconInstagramBlack />
    </a>
    <a
      href="https://www.pinterest.it/sognidicristallo/"
      target="_blank"
      class="socials-icon"
    >
      <SocialIconPinterestBlack />
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  SocialIconFacebookBlack,
  SocialIconInstagramBlack,
  SocialIconPinterestBlack,
} from '~/components/General/Icons';

export default defineComponent({
  name: 'Socials',
  setup() {},
  components: {
    SocialIconFacebookBlack,
    SocialIconInstagramBlack,
    SocialIconPinterestBlack,
  },
});
</script>

<style lang="scss" scoped>
.socials-icon {
  svg {
    transform: scale(0.75);
  }
}
</style>
